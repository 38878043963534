<script setup lang="ts">
import { BaseFetchWrapper, BaseSpinner, useAsyncState } from '@legacy-studio/library';
import { getCategories, getTournaments } from '../core/application';
import {
	useTournamentsCategories,
	useTournamentsItems,
} from '../infrastructure/store';
import { UiDarkBBButton } from '~/src/shared/ui';

import ListItem from '~/src/features/tournaments/ui/ListItem.vue';
import { terminalTournamentBus } from '~/src/features/tournaments/infrastructure/bus';

const getCategoriesCommand = useAsyncData(getCategories);
const getTournamentsCommand = useAsyncState(getTournaments);

const categories = computed(() => useTournamentsCategories());
const filterCategory = ref('current');

getTournamentsCommand.execute('current');

const tournaments = computed(() => useTournamentsItems());

watch(
	() => filterCategory.value,
	(value) => {
		getTournamentsCommand.execute(value);
	},
);
terminalTournamentBus.useSubscribeTournamentUpdated(async () => {
	await getTournamentsCommand.execute(filterCategory.value);
});
</script>

<template>
	<BaseFetchWrapper
		:pending="
			getCategoriesCommand.pending.value
		"
		:error="
			getCategoriesCommand.error.value || getTournamentsCommand.error.value
		"
	>
		<div class="flex flex-col">
			<div
				class="flex gap-4 overflow-auto whitespace-nowrap py-4 mb-4 px-4 -mx-4"
			>
				<UiDarkBBButton
					v-for="button in categories"
					:key="button.code"
					size="sm"
					:outline="filterCategory !== button.code"
					:button-text="button.name"
					class="!rounded-full"
					@click="filterCategory = button.code"
				/>
			</div>

			<div class="flex flex-col gap-4">
				<div
					v-if="getTournamentsCommand.isLoading.value && !getCategoriesCommand.pending.value"
					class="w-full justify-center flex p-4"
				>
					<BaseSpinner
						size="sm"
					/>
				</div>
				<list-item
					v-for="tournament in tournaments"
					v-else
					:key="tournament.id"
					:item="tournament"
					:status="filterCategory"
				/>
			</div>
		</div>
	</BaseFetchWrapper>
</template>
