import {
	saveTournamentsItems,
	saveTournamentsSelectedCategories,
} from '../../infrastructure/store';
import { tournamentsApi } from '~/src/features/tournaments/infrastructure/api';

export const getTournaments = async (status) => {
	saveTournamentsSelectedCategories(status);
	const result = await tournamentsApi.fetchTournaments(status);
	if (result.isRight()) {
		saveTournamentsItems(result.value);
	}
};
