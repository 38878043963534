import { tournamentsApi } from '../../infrastructure/api';
import {
	readTournamentsCategories,
	saveTournamentsCategories,
} from '../../infrastructure/store';

export const getCategories = async () => {
	const isCategoriesLoaded = !!readTournamentsCategories();

	if (!isCategoriesLoaded) {
		const result = await tournamentsApi.fetchCategories();
		if (result.isRight()) {
			saveTournamentsCategories(result.value);
		}
	}
};
